import React, { Component, useState, useEffect, useContext } from 'react';
import { FiArrowRight } from "react-icons/fi";
import {Link} from "react-router-dom";
import { MyContext } from "../MyContext";

const Books = () => {
  const { books } = useContext(MyContext);
  
  return (
    <div className='popular-books-card'>
      {/* <div className='popular-books-card-header'>
        <div className='popular-books-card-header-title'>POPULARNE</div>
      <Link to="/Store" style={{ textDecoration: 'none' }}><p className='popular-books-card-more'>Zobacz więcej &nbsp;{">"}</p></Link>
      </div> */}
      <div className='popular-books-card-books'>
        <Link to={`/books/${books[45].id}`} style={{textDecoration: 'none'}}>
        <div className='book-card'>
      <div><img src={require(`../assets/${books[45].isbn}.jpg`)} alt="" className='book-card-top-image'/></div>
      <div className="book-card-info-type-top">print</div>
      <div className="book-card-info-title-top">{books[45].title}</div>
            <div className="book-card-info-price-top">{books[45].price} zł</div>
            {/* <StarRating/> */}
      </div>
        </Link>
        <Link to={`/books/${books[46].id}`} style={{textDecoration: 'none'}}>
        <div className='book-card'>
      <div><img src={require(`../assets/${books[46].isbn}.jpg`)} alt="" className='book-card-top-image'/></div>
      <div className="book-card-info-type-top">print</div>
      <div className="book-card-info-title-top">{books[46].title}</div>
            <div className="book-card-info-price-top">{books[46].price} zł</div>
            {/* <StarRating/> */}
      </div>
        </Link>
        <Link to={`/books/${books[2].id}`} style={{textDecoration: 'none'}}>
        <div className='book-card'>
      <div><img src={require(`../assets/${books[2].isbn}.jpg`)} alt="" className='book-card-top-image'/></div>
      <div className="book-card-info-type-top">ebook</div>
      <div className="book-card-info-title-top">{books[2].title}</div>
            <div className="book-card-info-price-top">{books[2].price} zł</div>
            {/* <StarRating/> */}
      </div>

        </Link>
      </div>
      <div className='popular-books-card-header'>
        {/* <div className='popular-books-card-header-title'>POPULARNE</div> */}
      <Link to="/Store" style={{ textDecoration: 'none' }}><button className="secondary-button">Zobacz więcej &nbsp;{">"}</button></Link>
      </div>
    </div>
  )
}

export default Books