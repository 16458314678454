import React, { useEffect, useContext } from 'react';
import { Link } from "react-router-dom";
import {BiChevronDown} from 'react-icons/bi';
import { MyContext } from "../MyContext";
import StarRating from "./StarRating";
import ReactStars from "react-rating-stars-component";
import { useLocation } from "react-router-dom";



const Products = () => {
  const { books } = useContext(MyContext);

    useEffect(() => {
      document.querySelector('#top-store').scrollTo()
    }, [])



  const products = books.map(product => {
    return (
      <div key={product.id} className='product-card'>
        {/* <Link to={`/books/${product.id}`} style={{textDecoration: 'none'}} className='product-card-content'>
        <div className="book-card-img"><img src={require(`../assets/${product.isbn}.jpg`)} className='book-card-image'/></div>
        <div className="book-card-info">
            <div className="book-card-info-title">{product.title}</div>
            <div className="book-card-info-type">E-book</div>
                     <div className="book-card-info-price">{product.price} zł</div>
        </div>
        </Link> */}
         <Link to={`/books/${product.id}`} onClick={() => window.scrollTo(0, 0)} style={{textDecoration: 'none'}}>
        <div className='book-card'>
      <div><img src={require(`../assets/${product.isbn}.jpg`)} alt="" className='book-card-top-image'/></div>
      {/* <div className="book-card-info-type-top"> */}
        {product.isbn==='978-83-969323-8-9' || product.isbn==='978-83-969323-9-6' ? <div className="book-card-info-type-top">print</div> :<div className="book-card-info-type-top">ebook</div>}
        
      <div className="book-card-info-title-top">{product.title}</div>
            <div className="book-card-info-price-top">{product.price} zł</div>
            {/* <StarRating/> */}
      </div>
        </Link>
      </div>
    );
  });

  return (
    <>
   <div className='proba'>
       <div className='store-products' id='top-store'>
       <div className='store-header'>
             Książki i ebooki
         </div>
         <div className='store-row'>
         {products}
         </div>
     </div>
     </div>
    </>
  );
};

export default Products;
