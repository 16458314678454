import React, { Component, useState, useEffect, useContext } from 'react';

const Terms = () => {

      useEffect(() => {
            window.scrollTo(0, 0)
          }, [])

  return (
    <div className='terms'>
      <div className='terms-header'>
      <b>REGULAMIN SKLEPU - KSIĘGARNI INTERNETOWEJ</b><br/><br/>
      </div>
      <div className='terms-text'>
      Administratorem strony jest Radosław Więckowski<br/>
      86-031 Osielsko ul.Wierzbowa 7<br/>
Jeśli masz pytania skontaktuj się na adres email: radoslaw.wieckowski@gazeta.pl.
<br/><br/><br/><br/>
      </div>
      <div>
      <b>§ 1 Postanowienia ogólne</b><br/><br/>
      1. Księgarnia, dostępna pod adresem angielskie-ebooki.pl, jest prowadzona
      przez Radosława Więckowskiego<br/>
Adres: 86-031 Osielsko ul. Wierzbowa 7<br/>
E-mail: radoslaw.wieckowski@gazeta.pl<br/><br/>
2. Księgarnia prowadzi sprzedaż ebooków (treści cyfrowych w formatach
pdf i epub) oraz książek drukowanych za pośrednictwem sieci Internet.
Zamówienia są przyjmowane przez stronę https://angielskie-ebooki.pl<br/><br/>
3. Klient uprawniony jest i zobowiązany do korzystania z Księgarni zgodnie
z jej przeznaczeniem. Zabrania się wprowadzania treści o charakterze
bezprawnym.<br/><br/>
4. Definicje użyte w Regulaminie oznaczają:<br/>
Księgarnia prowadzona przez Radosława Więckowskiego, przyjmujący
zamówienia poprzez stronę https://angielskie-ebooki.pl<br/>
Klient – osoba fizyczna korzystająca z Eksięgarni, w szczególności
dokonująca zakupów.<br/>
Regulamin – niniejszy regulamin, określający zasady zakupów.<br/>
Produkt – ebook (treść cyfrowa w formatach epub lub pdf) oraz książka
papierowa (drukowana)<br/><br/>
      </div>
      <div>
      <b>§ 2 Zakupy w księgarni</b><br/><br/>
      1. Ceny Produktów, widoczne na stronie podawane w złotych polskich, są
      cenami brutto.<br/><br/>
      2. Księgarnia prowadzi sprzedaż ebooków (treści cyfrowych w formatach
pdf i epub) oraz książek drukowanych za pośrednictwem sieci Internet.
Zamówienia są przyjmowane przez stronę https://angielskie-ebooki.pl<br/><br/>
3. Klient po złożeniu zamówienia otrzymuje na swojego emaila wyszczególnioną
pozycję w postaci tytułu, nr konta bankowego, na który należy dokonać płatności za
wskazane treści, tj. MBANK 71 1140 2017 0000 4502 0314 2122. W tytule przelewu
należy wpisać nr zamówienia. Po zaksięgowaniu przelewu ebooki zostaną wysłane na
podany przez Klienta adres email, natomiast książki drukowane (w wersji papierowej w
oprawie miękkiej) zostaną wysłane na podany przez Klienta adres doręczenia. Koszt
wysyłki pokrywa Księgarnia.<br/><br/>
4. Złożenie zamówienia przez Klienta oznacza zawarcie umowy sprzedaży
(kupna) zamówionego Produktu.<br/><br/>
      </div>

      <div>
      <b>§ 3 Formy płatności za zamówienie</b><br/><br/>
      Na chwilę obecną istnieje jedna formy płatności za zamówienie: przelew standardowy
na konto MBANK 71 1140 2017 0000 4502 0314 2122 Radosław Więckowski 86-031
Osielsko ul. Wierzbowa 7. W tytule przelewu należy wpisać nr zamówienia.
Zamówienie nie opłacone w ciągu 48h zostają anulowane. W miarę rozwoju księgarni
zostaną dodane inne formy płatności.<br/><br/>
<b>§ 4 Odstąpienie od umowy – książki papierowe</b><br/><br/>
Klient może zwrócić tylko książkę drukowaną (w wersji papierowej) bez podania
przyczyny w terminie 14 dni od dnia jego otrzymania, pod warunkiem, że produkt ten
nie był używany. Należy przesłać go na nasz adres wraz z numerem zamówienia.
Gwarantujemy zwrot pieniędzy w terminie 14 dni od momentu otrzymania zwrotu.
Zwracający ponosi koszt przesyłki.<br/><br/>
      </div>
      <div>
      <b>§ 5 Odstąpienie od umowy – ebooki (produkty/treści cyfrowe)</b><br/><br/>

      Zgodnie z art. 38 ustawy o prawach konsumenta prawo odstąpienia od umowy zawartej
poza lokalem przedsiębiorstwa lub na odległość nie przysługuje konsumentowi w
odniesieniu do umów: o dostarczanie treści cyfrowych, które nie są zapisane na
nośniku materialnym, jeżeli spełnianie świadczenia rozpoczęło się za wyraźną zgodą
konsumenta przed upływem terminu do odstąpienia od umowy i po poinformowaniu go
przez przedsiębiorcę o utracie prawa odstąpienia od umowy. Po zakupie ebooka w
formatach pdf lub epub (treść cyfrowa) konsument traci prawo do odstąpienia od
umowy.<br/><br/>

      </div>
      <div>
      <b>§ 6 Postanowienia końcowe</b><br/><br/>
      1. W sprawach nieuregulowanych niniejszym Regulaminem, zastosowanie mają
powszechnie obowiązujące przepisy prawa polskiego, a zwłaszcza Kodeksu cywilnego
oraz przepisy Ustawy o szczególnych warunkach sprzedaży konsumenckiej oraz
zmianie Kodeksu cywilnego (Dz. U. Nr 141 z 2002 r. poz. 1176 z późn. zm.) i Ustawy o
ochronie niektórych praw konsumentów oraz o odpowiedzialności za szkodę
wyrządzoną przez produkt niebezpieczny (Dz. U. Nr 22 z 2000 r. poz. 271 z późn. zm.).<br/><br/>
2. Księgarnia nie ponosi odpowiedzialności za podanie błędnych danych
przez Klienta.<br/><br/>
3. Księgarnia zastrzega sobie prawo do zmiany Regulaminu jedynie z
ważnych przyczyn, takich jak usprawnienie działalności serwisu,
wprowadzenie nowych funkcjonalności, rozpoczęcie świadczenia nowych
usług lub zmiana przepisów prawa.
      </div>
    </div>
  )
}

export default Terms