import {useParams} from "react-router-dom"
import React, { Component, useState, useEffect, useContext } from 'react';
import { Link } from "react-router-dom";
import BookCardStore from './BookCardStore';
import {BiChevronDown} from 'react-icons/bi';
import axios from "axios";
import BookCover from '../assets/ISBN 978-83-968474-7-8.jpg';
import StarRating from "./StarRating";
import { MyContext } from "../MyContext";
import { ShopContext } from "../context/shop-context";

function BookAbout() {
  const { books } = useContext(MyContext);

    const {productId} = useParams()
    const thisProduct = books.find(prod => {return prod.id === +productId})
    const quantity = 0;

  const {addToCart} = useContext(ShopContext);

    useEffect(() => {
      const element = document.getElementById('#about-top');
      element.scrollIntoView({ behavior: "instant", block: "start" });
   }, []);

    return (
      <div className='book-about' id="book-about">
              <Link to='/store' ><div className="book-about-return" id="#about-top">&lt; Wróć</div></Link>
               <div className="book-about-section">
                 <div className="book-about-section-top">
                 <div className="book-about-image"><img src={require(`../assets/${thisProduct.isbn}.jpg`)} alt=""/></div>
                 <div className='book-description'>
                   <div className='book-about-title'>{thisProduct.title}</div>
                   <div className="book-about-tags">
                   {
                (() => {
                    if(thisProduct.isbn==='978-83-969323-8-9' || thisProduct.isbn==='978-83-969323-9-6') {
                            return (
                              <div className="book-card-info-type-top">print</div>
                            )
                        } else if (thisProduct.isbn==='ISBN 978-83-961673-3-0' || thisProduct.isbn==='ISBN 978-83-968474-6-1' || thisProduct.isbn==='ISBN 978-83-968474-7-8') {
                            return (
                              <div className="book-card-info-type-top">epub</div>
                            )
                        } else {
                            return (
                              <div className="book-card-info-type-top">pdf</div>
                            )
                        }
                })()  
            } 

                   </div>
                   <div className="book-about-section-top-price">{thisProduct.price} zł</div>
                   <div className="book-about-additional-info">
                   <div className="book-about-section-top-pages">
                     <b>Liczba stron</b> {thisProduct.pages}
                   </div>
                   <div className="book-about-section-top-size">
                   </div>
                   </div>
                   <StarRating/>
                   {quantity === 0 ? (<button className="primary-button-add-to-cart" onClick={()=> addToCart(thisProduct)}>Do koszyka</button>) : null}
               </div>
                 </div>
                 <div className="book-about-section-bottom">
                   <div className="book-about-section-bottom-headers">
                     <div className="book-about-section-bottom-header">OPIS</div>
                     {/* <div className="book-about-section-bottom-header">OPINIE(0)</div> */}
                   </div>
                   <div className="book-about-section-bottom-description">
                    <pre className="book-about-section-bottom-description-item" >{thisProduct.description}</pre>
                   </div>
                 </div>
               </div>
          
             </div>
    )
}

export default BookAbout